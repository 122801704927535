export const getComboBoxInputListModalId = (parentCompId: string) =>
  `listModal_${parentCompId}`;

export const scrollDownToOption = ({
  optionEl,
  listEl,
}: {
  optionEl: HTMLDivElement | null;
  listEl: HTMLDivElement | null;
}) => {
  if (optionEl && listEl && optionEl.offsetTop > listEl.offsetHeight) {
    listEl.scrollTop =
      optionEl.offsetTop - listEl.offsetHeight + optionEl.offsetHeight;
  }
};

export const scrollUpToOption = ({
  optionEl,
  listEl,
  hoveredOptionIndex,
}: {
  optionEl: HTMLDivElement | null;
  listEl: HTMLDivElement | null;
  hoveredOptionIndex: number;
}) => {
  if (optionEl && listEl && optionEl.offsetTop < listEl.scrollTop) {
    listEl.scrollTop = optionEl.offsetHeight * hoveredOptionIndex;
  }
};
